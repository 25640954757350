// Copyright 2021
// Polus
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Callout from '../../components/Callout';
import FeaturePageContainer from '../../components/features/FeaturePageContainer';
import ImageWithTextSection from '../../components/features/ImageWithTextSection';
import Layout from '../../components/Layout';
import Seo from '../../components/SEO';

const ImageOcclusion = (): React.ReactElement => (
  <Layout>
    <Seo title="Image Occlusion" />
    <FeaturePageContainer>
      {/* TODO: make this a shared component */}
      <div className="flex flex-col items-center justify-around space-y-8 space-x-0 md:flex-row md:space-y-0 md:space-x-8">
        <div className="w-full max-w-[28rem]">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            height="320"
            src="https://www.youtube.com/embed/boHKd88osmQ"
            title="YouTube video player"
            width="100%"
            allowFullScreen
          />
        </div>
        <div className="md:w-1/2">
          <h1 className="mb-3 text-2xl font-bold md:text-4xl">Image Occlusion</h1>
          <p>
            Image Occlusion helps you study any image that contains labels: annotated diagrams,
            charts, maps and more. Studius generates flashcards for each label and prompts you with
            one card at a time, while the other labels are hidden.
          </p>
        </div>
      </div>

      <ImageWithTextSection
        content="Upload any image you want to study with Image Occlusion. You can hide or occlude labels by placing boxes over them. Studius will generate a card for each box."
        header="Generate cards from labels"
        image={
          <StaticImage
            alt="Spaced repetition example in the Study mode"
            quality={100}
            src="../../images/spaced-repetition/generate-cards-from-labels.png"
          />
        }
      />

      <ImageWithTextSection
        content="For every box you place over a label, a card is automatically generated. In the Study mode, only one card is highlighted at a time. Every card enters the spaced repetition separately, so that all cards eventually end up in your long-term memory."
        header="Study one label at a time"
        image={
          <StaticImage
            alt="Spaced repetition example in the Study mode"
            className="w-5/6"
            quality={100}
            src="../../images/spaced-repetition/study.png"
          />
        }
      />

      <ImageWithTextSection
        content="By default, a deck supports all card types. You can study text-based and Image Occlusion cards in the same deck."
        header="Mix and match with other card types"
        image={
          <StaticImage
            alt="Spaced repetition example in the Study mode"
            quality={100}
            src="../../images/spaced-repetition/mix-and-match.png"
          />
        }
      />

      <Callout label="Convinced? Use Studius to study diagrams and images with labels." />
    </FeaturePageContainer>
  </Layout>
);

export default ImageOcclusion;
